<template>
  <div>
   111111111
  </div>
</template>

<script>
import { mapActions} from "vuex";
export default {
  
  name: "aaatext",
  components: {
   
  },
  data() {
    return {
      matchType: "",
    };
  },
   methods:{
     ...mapActions("tabs", [
      "handleTabsEdit",
      "addTabs",
      "handleTabsClick",
      "removeTab",
      "removeTabByPath",
    ])
   }
  //组件被激活时
  // async activated() {
  //   this.matchType = localStorage.getItem("matchType");
  //   this.$store.commit("tabs/setVNode", this.$vnode);
  // },
};
</script>

<style scoped></style>
